<template>
  <div>
    <h1 class="mb-6 font-display text-4xl">Support information</h1>
    <div class="relative flex rounded-md border bg-white shadow">
      <div
        class="flex flex-col space-y-4 border-r p-6"
        :style="{ minWidth: '180px' }"
      >
        <button
          v-for="button in buttons"
          :key="button"
          class="font-black"
          :class="{ 'text-primary-darker': selected === button }"
          @click="selected = button"
        >
          {{ button }}
        </button>
      </div>

      <div class="flex-shrink p-6">
        <h2 class="text-2xl font-semibold leading-none">{{ selected }}</h2>
        <div class="py-4 text-base lg:pr-20">
          <technical-support
            v-if="selected === 'Technical Support'"
            class="space-y-4"
          />
          <customer-support
            v-if="selected === 'Customer Support'"
            class="space-y-4"
          />
          <veterinary-support
            v-if="selected === 'Veterinary Support'"
            class="space-y-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TechnicalSupport from '@/components/support/TechnicalSupport';
import CustomerSupport from '@/components/support/CustomerSupport';
import VeterinarySupport from '@/components/support/VeterinarySupport';

export default {
  components: {
    TechnicalSupport,
    CustomerSupport,
    VeterinarySupport,
  },
  data() {
    return {
      buttons: ['Technical Support', 'Customer Support', 'Veterinary Support'],
      selected: 'Technical Support',
    };
  },

  methods: {},
};
</script>
