var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [_vm._v("How we work")]),
        _c("p", [
          _vm._v(
            " Our technical veterinary support at FirstVet focuses on troubleshooting problems in VetPanel reported by our Veterinarians via lifebuoys. Tech investigates what the problem is and in some cases escalates it further to our developers. Tech usually does not help with personal technical support connected to the veterinarians computer, equipment or network. We take part of all lifebuoys that are sent to us, but we usually do not answer all of them unless the veterinarian has asked a question or if we need more information. "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [
          _vm._v("When and how to contact tech support"),
        ]),
        _c("p", [
          _vm._v(
            " Any difficulties regarding technical issues in VetPanel should go to tech support via a lifebuoy. It can be anything from technical problems during a consultation to technical problems and bugs in VetPanel. Please always use a lifebuoy if you have questions, want to report a problem during a video consultation or report a bug. It is important that you always send us a ticket in case of problems in the meeting room or in VetPanel in general, with the exception of audio and video problems where you can see in the meeting room that your or the owner's connection is bad. Sometimes, we can reach out to you on Slack if we need more information after submitting a lifebuoy. Please do not send direct messages in Slack to tech support. "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [
          _vm._v("Business hours for tech support"),
        ]),
        _c("p", [
          _vm._v(
            " Tech Support is available monday-friday 8am-5pm GMT+2. On Swedish holidays, the tech support is closed. We usually review your ticket within 3 business days. If you have an urgent technical problem outside business hours that prevents you from working, write in the channel for schedule questions in Slack that you need help and one of our outside office hours staff will help you. "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [_vm._v("Tech support team")]),
        _c("p", [
          _vm._v(
            " Johan Lind is responsible for the technical support lifebuoys for our veterinarians in all our markets, as well as support to our HQ staff and responsibility for our internal IT. "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [
          _vm._v("Troubleshooting audio and video"),
        ]),
        _c(
          "a",
          {
            staticClass: "text-primary-darker underline",
            attrs: {
              href: "https://docs.google.com/document/d/1wPfH5fmNJTyP7OEdpjd-6Ore2SpcvgjzTg6bi1VwnB8/edit?usp=sharing",
              target: "_blank",
            },
          },
          [_vm._v(" Tech FAQ ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }