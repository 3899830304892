var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [_vm._v("How we work")]),
        _c("p", [
          _vm._v(
            " When it comes to matters regarding insurance, billing, wrong animal or double journals, refunds or any external contact, this is the channel to get help from. "
          ),
          _c("strong", [_vm._v("Note")]),
          _vm._v(
            ": Customer support is for our customers, e.g external communication. We can't help you with scheduling or technical issues. "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [
          _vm._v("When and how to contact customer support"),
        ]),
        _c("p", [
          _vm._v(
            " Primarily contact us through lifebuoys with various degrees of emergency. Otherwise use Slack channels appointed for customer support. Do not use DMs, as that is not considered an effective way for communication. High: Almost no customer support issues are emergent enough for a High priority. If it's a technical issue, send it to tech. If it is veterinary, send it to vet-support. Medium: Change information on customer profiles for journal purposes, insurance tickets, billing Low: Merge two journals, refunds "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [
          _vm._v("Business hours for customer support"),
        ]),
        _c("p", [
          _vm._v(" Sweden: Monday-Friday 08:00-17:00"),
          _c("br"),
          _vm._v(" FI: Monday-Friday 09:00-16:00"),
          _c("br"),
          _vm._v(" NO: Monday - Friday 09:00-16:00"),
          _c("br"),
          _vm._v(" DK: Monday-Friday 09:00-16:00"),
          _c("br"),
          _vm._v(" DE: Monday-Friday 09:00-18:00"),
          _c("br"),
          _vm._v(" UK: Monday-Friday 09:00-17:00 "),
          _c("br"),
          _vm._v(" US: Monday-Friday 08:00-16:00 EST "),
        ]),
      ]),
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [
          _vm._v("Customer support team"),
        ]),
        _c("p", [
          _vm._v(" Jessica Henriksson, Head of Customer Service Global"),
          _c("br"),
          _vm._v(" Astrid Lööf, Customer Service Agent Sweden"),
          _c("br"),
          _vm._v(" Isak Ström, Customer Service Agent Sweden "),
          _c("br"),
          _vm._v(" Tilma Bengtsson, Customer Service Agent Sweden"),
          _c("br"),
          _vm._v(" Felicia Huss, Customer Service Agent Sweden (part-time)"),
          _c("br"),
          _vm._v(" Edwin Lavilla, Customer Service Agent UK"),
          _c("br"),
          _vm._v(" Jonathan Smith, Customer Service Agent US"),
          _c("br"),
          _vm._v(" Germany: Michelle Rummer, Ciara Richards"),
          _c("br"),
          _vm._v(" Norway: Simen Fjell, Jessica Henriksson"),
          _c("br"),
          _vm._v(" Finland: Anne-Mari Sjölund, Jessica Henriksson"),
          _c("br"),
          _vm._v(" Denmark: Christine Scott, Martin Hansen "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }