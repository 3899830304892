var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "mb-6 font-display text-4xl" }, [
      _vm._v("Support information"),
    ]),
    _c(
      "div",
      { staticClass: "relative flex rounded-md border bg-white shadow" },
      [
        _c(
          "div",
          {
            staticClass: "flex flex-col space-y-4 border-r p-6",
            style: { minWidth: "180px" },
          },
          _vm._l(_vm.buttons, function (button) {
            return _c(
              "button",
              {
                key: button,
                staticClass: "font-black",
                class: { "text-primary-darker": _vm.selected === button },
                on: {
                  click: function ($event) {
                    _vm.selected = button
                  },
                },
              },
              [_vm._v(" " + _vm._s(button) + " ")]
            )
          }),
          0
        ),
        _c("div", { staticClass: "flex-shrink p-6" }, [
          _c("h2", { staticClass: "text-2xl font-semibold leading-none" }, [
            _vm._v(_vm._s(_vm.selected)),
          ]),
          _c(
            "div",
            { staticClass: "py-4 text-base lg:pr-20" },
            [
              _vm.selected === "Technical Support"
                ? _c("technical-support", { staticClass: "space-y-4" })
                : _vm._e(),
              _vm.selected === "Customer Support"
                ? _c("customer-support", { staticClass: "space-y-4" })
                : _vm._e(),
              _vm.selected === "Veterinary Support"
                ? _c("veterinary-support", { staticClass: "space-y-4" })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }