<template>
  <default-layout>
    <support-section />
  </default-layout>
</template>

<script>
import SupportSection from '@/components/support/SupportSection';

export default {
  components: {
    SupportSection,
  },

  methods: {},
};
</script>
