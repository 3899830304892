<template>
  <default-layout>
    <div class="relative mx-auto">
      <ClinicMap
        :stand-alone="true"
        class="h-full w-full flex-shrink-0 rounded"
        :country="user.country_id || 826"
      />
    </div>
  </default-layout>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ClinicMap from '@/components/clinic-map/ClinicMap';

export default {
  components: {
    ClinicMap,
  },
  computed: {
    ...mapState('user', ['user']),
  },
  mounted() {
    this.setAppointmentCleared(true);
  },
  methods: {
    ...mapMutations('appointment', ['setAppointmentCleared']),
  },
};
</script>

<style lang="scss" scoped>
.map-container-wrapper {
  height: calc(100vh - 67px);
}
</style>
