var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [_vm._v("How we work")]),
        _c("p", [
          _vm._v(
            " Your Lead Vet & Admin Vets will help you with problems or questions concerning how to handle difficult cases, cases where you want us to watch the video/journal, or discuss anything else after the consultation for any reason. "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [
          _vm._v("When and how to contact vet support"),
        ]),
        _c("p", [
          _vm._v(
            " You should also always use the support function in VetPanel if you have a case that might be reported due to lack of animal welfare, to make us aware of the case. "
          ),
          _c("strong", [_vm._v("Note")]),
          _vm._v(
            ": For medical questions, please post on Slack where a large number of veterinary colleagues can give advice if needed. The lifebuoy in VetPanel should not be used for this. "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [
          _vm._v("Business hours for vet support"),
        ]),
        _c("p", [
          _vm._v(" Sweden: Monday-Friday 08:00-18:00"),
          _c("br"),
          _vm._v(" FI: Monday-Friday 08:00-18:00"),
          _c("br"),
          _vm._v(" NO: Monday - Friday 08:00-18:00"),
          _c("br"),
          _vm._v(" DK: Monday-Friday 08:00-18:00"),
          _c("br"),
          _vm._v(" DE: Monday-Friday 08:00-18:00"),
          _c("br"),
          _vm._v(" UK: Monday-Friday 09:00-17:00"),
          _c("br"),
          _vm._v(" US: Monday-Friday 09:00-18:00 EST "),
        ]),
      ]),
      _c("div", [
        _c("h3", { staticClass: "font-black" }, [_vm._v("Vet support team")]),
        _c("p", [_vm._v("Your local Lead Vet and Admin Vets.")]),
      ]),
      _c("div", [
        _c("p", [
          _c(
            "a",
            {
              staticClass: "text-primary-darker underline",
              attrs: {
                target: "_blank",
                href: "https://docs.google.com/document/d/1-4S52UcOL3AQaoZQd5rMWmWVOdqHfyXPfjEboTCslxI/edit#",
              },
            },
            [_vm._v(" Q&A Sweden ")]
          ),
        ]),
        _c("p", [
          _c(
            "a",
            {
              staticClass: "inline-block text-primary-darker underline",
              attrs: {
                target: "_blank",
                href: "https://docs.google.com/document/d/1V3upZzmBabjJs29Z1R26EvsNKGC0tSrtINmXdO83XSU/edit?usp=sharing",
              },
            },
            [_vm._v(" Q&A DE ")]
          ),
        ]),
        _c("p", [
          _c(
            "a",
            {
              staticClass: "inline-block text-primary-darker underline",
              attrs: {
                target: "_blank",
                href: "https://docs.google.com/document/d/1pORQ8kgDNgScNMfjmVvxMo-WYS6N0UGqliKvKrc4SWE/edit?usp=sharing",
              },
            },
            [_vm._v(" Q&A UK ")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }