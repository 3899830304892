var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("default-layout", [
    _c(
      "div",
      { staticClass: "relative mx-auto" },
      [
        _c("ClinicMap", {
          staticClass: "h-full w-full flex-shrink-0 rounded",
          attrs: { "stand-alone": true, country: _vm.user.country_id || 826 },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }